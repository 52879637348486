.reset-password {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%; /* No fixed width, let it be responsive */
  padding: 0 20px; /* Add some padding for responsiveness */
}

button {
  border-radius: 5px;  /* Makes the button corners rounded */
  padding: 5px 15px;  /* Add some padding for better appearance */
  background-color: #007bff; /* Button color */
  color: white;  /* Text color */
  border: none;  /* No border */
  cursor: pointer; /* Cursor changes to pointer on hover */
  margin-top: 10px;
}

button:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

input[type="password"] {
  width: 100%;  /* Makes the input field full-width */
  padding: 5px;  /* Adds padding inside the input field */
  border: 1px solid #ccc;  /* Adds a border to the input */
  border-radius: 5px;  /* Rounds the corners of the input */
  box-sizing: border-box;  /* Ensures padding doesn't affect width */
}

.form-group {
  margin-bottom: 5px;  /* Space between input groups */
  width: 300px;
}

.App {
  text-align: center;
}

.App-header h1 {
  user-select: none;
}

.App-logo {
  height: 20vmin;
  margin-bottom: 50px;
  pointer-events: none;
  user-select: none;
  -webkit-user-drag: none;
}

img {
  pointer-events: none;
  -webkit-user-drag: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    /*animation: heartbeat 1.5s infinite linear;*/
  }
}

.success-message{
  background-color: rgba(26,162,96,0.2); 
  padding-inline: 10px;
  border-radius: 5px; 
  border: 2px solid rgba(26,162,96,0.2);
  color: white; 
}

.error-message{
  background-color: rgba(255,59,48,0.1);  
  padding-inline: 10px; 
  border-radius: 5px; 
  border: 2px solid rgba(255,59,48,0.2);
  color: white; 

}

/* Fade-in Animation Styles */
.fade-in {
  opacity: 0;
  transform: scaleY(0); /* Start with zero scale */
  /* transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;  /* Transition for both opacity and scale */
}

.fade-in-active {
  opacity: 1;
  transform: scaleY(1); /* Full size on active */
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.spinner{
  padding: 10px;
}

.bottom-corner {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 20px;
  display: flex;
  align-items: center;
}

.bottom-corner-img {
  width: 75px; /* Adjust the size of your image */
  height: auto;
  margin-right: 10px; /* Adjust spacing between image and text */
  margin-left: 10px;
}

.bottom-corner-text {
  color: #ffffff; /* Adjust text color */
  font-size: 16px; /* Adjust font size */
}

.qr-corner {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 20px;
  display: flex;
  align-items: center;
}

.app-store-button {
  display: inline-block;
  transition: transform 0.3s ease-in-out;
  transform-origin: bottom left; /* Expands upwards and to the right */
}

.app-store-button:hover {
  transform: scale(1.1); /* Slight zoom effect on hover */
}

.app-store-button img {
  width: 150px;
  height: auto;
  border-radius: 10px; /* Optional: Slightly rounded corners */
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(6px + 1vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 20px solid white; /* Adjust color and size as needed */
  position: absolute; /* Position the arrow relative to its container */
  bottom: 20px; /* Moves the arrow just off the bottom */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Perfectly center the arrow */
}

.no-select {
  user-select: none; /* Prevents text selection */
}

.nav-buttons {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
}

.nav-button {
  text-decoration: none;
  background-color: #007bff;
  color: white;
  padding: 8px 15px;
  border-radius: 5px;
  transition: background 0.3s;
}

.nav-button:hover {
  background-color: #0056b3;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@keyframes App-logo-move-horizontal {
  0%, 100% {
    transform: translateX(-100px);
  }
  50% {
    transform: translateX(100px); /* Adjust the value as needed */
  }
}

@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  10% {
    transform: scale(1.07);
  }
  12% {
    transform: scale(1.13);
  }
  100% {
    transform: scale(1);
  }
}
