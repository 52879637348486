/* Guide Container */
.guide-container {
  font-family: "Arial", sans-serif;
  color: #333;
  text-align: center;
  padding: 20px;
}

/* Header */
.guide-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  gap: 10px;
}

.guide-logo {
  width: 30px; /* Set your desired width */
  aspect-ratio: 1 / 1; /* Keeps it square */
  object-fit: contain; /* Ensures the image scales without distortion */
}

.guide-header h1 {
  font-size: 24px;
  font-weight: 400;
}

/* Introduction */
.guide-intro {
  margin: 20px;
  text-align: center;
}

.guide-intro h2 {
  font-size: 22px;
  margin-bottom: 10px;
}

.guide-intro p {
  font-size: 14px;
  color: #555;
}

/* Guide Steps (Screenshot Sections) */
.guide-steps {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  margin-top: 20px;
}

/* Animated Step */
.guide-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  padding-left: 10px;
  padding-right: 10px;
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  opacity: 0; /* Default hidden for animation */
}

.guide-image {
  width: 100%;
  max-width: 400px;
  border-radius: 10px;
}

.guide-text {
  font-size: 18px;
  margin-top: 10px;
}

.title {
  padding-left: 20px;
}

.primaryText {
  padding-left: 30px;
}

/* screenshot */
.screenshot {
  margin-bottom: 10px;
  max-width: 100%; /* Makes the image responsive */
  height: auto; /* Maintains aspect ratio */
  object-fit: contain; /* Ensures image fits well within container */
  max-height: 100%; /* Prevents clipping vertically */
}


/* Style for the content buttons */
.toc li {
  cursor: pointer;
  padding: 20px;
  margin: 5px 30px;
  color: #007BFF; /* Button color */
  font-size: 1.1rem;
  font-weight: 500;
  border-radius: 8px;
  transition: all 0.3s ease;
}

/* Remove bullet points from the list */
.toc ul {
  list-style-type: none; /* Removes the bullet points */
  padding: 0; /* Removes any default padding */
}

.toc li {
  padding: 10px; /* Add some padding for better spacing */
  cursor: pointer; /* Change cursor to pointer for clickable items */
  transition: transform 0.2s, box-shadow 0.2s; /* Smooth transition for effects */
}

/* Hover effect */
.toc li:hover {
  color: #0056b3; /* Darker color on hover */
  background-color: rgba(0, 123, 255, 0.1); /* Light background on hover */
  transform: scale(1.005); /* Slight scale on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); /* Add shadow on hover */
}

/* Active effect - when the button is being pressed */
.toc li:active {
  transform: scale(0.98); /* Shrink the button slightly */
  background-color: rgba(0, 123, 255, 0.2); /* Darker background when pressed */
}

/* Focus effect for accessibility */
.toc li:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5); /* Blue outline when focused */
}

/* Add a subtle shadow when not active or hovered */
.toc li:not(:active):not(:hover) {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

/* Title style (if needed for the heading) */
.toc .title {
  font-size: 1.5rem; /* Adjust font size for the title */
  margin-bottom: 10px; /* Space between title and list */
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px 15px;
  font-size: 14px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  transition: opacity 0.3s, transform 0.3s;
}

.back-to-top:hover {
  background-color: #0056b3;
}

.back-to-top:active {
  transform: scale(0.95);
}
